<template>
  <a-menu mode="inline" :inlineIndent="40" :default-selected-keys="[defaultSelected]" v-model="menuSelected">
    <template v-for="item in menuItems" :key="item.key">
      <a-menu-item style="text-align: left" @click="handleSelectMenu(item.key)">
        {{ item.title }}
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenuShop',
  props: {
    itemSelected: {
      type: String,
    },
  },
  data: () => ({
    menuItems: [
      { order: 1, title: 'Market', key: 'menu-market' },
      { order: 2, title: 'Facturación', key: 'menu-billing' },
      { order: 3, title: 'Métodos de pago', key: 'menu-payment-methods' },
      // { order: 3, title: 'Recargas y gastos', key: 'menu-recharge-spending' },
    ],
    menuSelected: [''],
  }),
  watch: {
    nameRoute(val) {
      this.menuSelected = [val]
    },
  },
  mounted() {
    setTimeout(() => {
      /** @type {{order: number, title: string, key: string}[]} */
      const menuItems = this.menuItems
      const HIDE_RECHARGE_EXPENSES_FOR_IDS = process.env.VUE_APP_HIDE_RECHARGE_EXPENSES_FOR_IDS
      if (HIDE_RECHARGE_EXPENSES_FOR_IDS) {
        /** @type {Record<string, any>} */
        const company = this.company
        if (!HIDE_RECHARGE_EXPENSES_FOR_IDS.split(',').includes(company?.spaceId)) menuItems.push({ order: 3, title: 'Recargas y gastos', key: 'menu-recharge-spending' })
      }
    }, 1500)
    this.menuSelected = [this.defaultSelected]
  },
  computed: {
    ...mapGetters(['company']),
    // menu por default seleccionado
    defaultSelected() {
      if (!this.itemSelected) {
        const menuItem = this.menuItems.find((item) => item.order === 1)
        this.handleSelectMenu(menuItem.key)
        return menuItem.key
      } else return this.itemSelected
    },
    /**
     * @returns {string}
     */
    nameRoute() {
      return this.$route.name
    },
  },
  methods: {
    handleSelectMenu(menuItem) {
      this.$router.push({ name: menuItem })
    },
  },
}
</script>
<style lang="sass" scoped></style>
